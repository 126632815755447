import React from "react"
import { graphql } from "gatsby"
import * as PropTypes from "prop-types"
import Helmet from "react-helmet"

import { Breadcrum, Set, PostTokens, SEO, PostHeader, SocialLinks } from "../../components/index"
import * as postStyles from '../../components/posts/posts.module.scss'
import { artworkTokenType, baseTokenType, allPostsType } from '../../types'
import { getDefaultPostData } from '../../utils/structuredData'

class FoilTokensPage extends React.PureComponent {
  constructor(props) {
    super(props);
    const { title, route } = props.data.allPostsJson.edges[0].node;
    this.breadcrumPages = [
      {
        text: 'Posts',
        to: '/posts/'
      },
      {
        text: title,
        active: true,
        to: route
      }
    ];
    this.backBreadcrumPages = [
      {
        ...this.breadcrumPages[0],
      },
      {
        text: title,
        to: route
      }
    ];
  }

  render () {
    const { foilTokens, allPostsJson } = this.props.data;
    const { 
      title, author, date, shortDescription, imgSrc, theme, route, formattedDate,
      socialImgSrc: { childImageSharp: { fluid: { src: socialSrc } } }
    } = allPostsJson.edges[0].node;
    const firstToken = foilTokens.edges[0];
    const lastToken = foilTokens.edges[foilTokens.edges.length -1]
    return (
      <>
        <SEO title={title} description={shortDescription} pageUrl={route} imgSrc={socialSrc} />
        <Helmet>
          <script type="application/ld+json">{getDefaultPostData({
            route: route, title: title, 
            shortDescription: shortDescription, date: date,
            keywords: ['foil', 'rare', 'expensive', 'collection'],
            img: socialSrc,
            modifiedDate: date
          })}
          </script>
        </Helmet>
        <Breadcrum pages={this.breadcrumPages} />
        <article className={postStyles.article}>
          <PostHeader
            imgSrc={imgSrc}
            title={title}
            author={author}
            date={formattedDate}
            theme={theme}
          />
          <SocialLinks title={title} />
          <div className={postStyles.body}>
            <p>Foil Magic: the Gathering (MTG) cards are also known as premium cards, they are cards that have a glossy, foil finish to them. Foil cards are randomly inserted into booster packs, with approximately one foil in every six packs. As a result foil cards are rarer and much more valuable than non-foil cards.</p>
            <p>However, it’s only common, uncommon, rare and mythic foil cards that are found in booster packs, Tokens cards are not... Foil tokens have only been printed as promotional cards and are mostly given away as prizes or promos for MTG pre-release events and Friday Night Magic (FNM).</p>
            <p>To date, only <span className="big">{foilTokens.totalCount}</span> foil token cards have been officially printed by Wizards of the Coast. The first was a {firstToken.node.token.name} token from <Set id={firstToken.node.set.id} size="sm" />{firstToken.node.set.name} printed on {firstToken.node.set.releaseDate} and most recently a  {lastToken.node.token.name} token from <Set id={lastToken.node.set.id} size="sm" />{lastToken.node.set.name} printed in {lastToken.node.set.releaseDate}. Browse all of the foil tokens below.</p>
          </div>
          <PostTokens
            title="All foil MTG Tokens:"
            tokens={foilTokens.edges}
            breadcrum={this.backBreadcrumPages}
          />
        </article>
      </>
    )
  }
}

FoilTokensPage.propTypes = {
  data: PropTypes.shape({
    allPostsJson: allPostsType.isRequired,
    foilTokens: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...artworkTokenType,
          token: PropTypes.shape({
            ...baseTokenType
          })
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}

export const foilTokensPageQuery = graphql`
  query FoilTokensPageQuery {
      allPostsJson(filter: {
        id: {
          eq: "post1"
        }
      }) {
        edges {
          node {
            id
            title
            author
            route
            shortDescription
            imgSrc {
              childImageSharp {
                fluid(maxWidth: 270) {
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            socialImgSrc {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            theme
            date
            formattedDate: date(formatString: "MMMM Do, YYYY")
          }
        }
      }
      foilTokens: allTokensJson(filter: {
        foil: {
          eq: true
        }
      }) {
        totalCount
        edges {
          node {
            id
            token {
              id
              name
              power
              toughness
            }
            foil
            promo
            imgSrc {
              childImageSharp {
                fluid(maxWidth: 175) {
                  base64
                  src
                  srcSet
                  aspectRatio
                  sizes
                }
              }
            }
            set {
              id
              name
              releaseDate(formatString: "MMM Do, YYYY")
            }
          }
        }
      }
    }
  `

export default FoilTokensPage
